import React from "react"
import OtpInput from "react-otp-input"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

const Container = styled.div`
  text-align: center;

  h1 {
    margin-bottom: ${rem(40)};
  }
`

const Field = styled.div`
  ${props => props.theme.shapeNotched()}

  width: 90%;
  max-width: ${rem(440)};
  padding: ${rem(10)} ${rem(20)};
  margin: 0 auto;
  background-color: ${props => props.theme.colorGreyLightest};

  ${props =>
    props.displayError &&
    css`
      background: rgb(255, 157, 154);
    `}

  .-container {
    justify-content: center;

    > div {
      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }
  }

  .-input {
    display: block;
    font-family: ${props => props.theme.ffSecondary};
    font-size: ${rem(36)};
    border-bottom: 2px solid;

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(24)};
    }

    &:focus {
      border-color: ${props => props.theme.colorGrey};
    }
  }
`

class Dob extends React.Component {
  state = {
    value: ``,
    hasError: true,
    displayError: false,
  }

  componentDidMount() {
    if (this.fieldEl) {
      this.fieldEl
        .querySelectorAll(`.-input`)
        .forEach(input =>
          input.addEventListener(`keyup`, this.inputKeyup, { passive: true })
        )
    }
  }

  yearValidation(enteredYear) {
    const currentYear = new Date().getFullYear()
    const minYear = currentYear - 90

    if (enteredYear < minYear || enteredYear > currentYear) {
      this.setState({
        hasError: true,
      })
    } else {
      this.setState({
        hasError: false,
        displayError: false,
      })

      this.props.onSelect(this.state.value)
    }
  }

  inputChange = value => {
    this.setState({ value })
  }

  inputKeyup = e => {
    this.yearValidation(this.state.value)

    if (e.key === `Enter`) {
      if (this.state.hasError) {
        this.setState({
          displayError: true,
        })
      }
    }
  }

  render() {
    const { onSelect, ...props } = this.props // eslint-disable-line

    return (
      <Container {...props}>
        <h1 className="styled-h1">What year were you born?</h1>

        <Field
          displayError={this.state.displayError}
          ref={el => (this.fieldEl = el)}
        >
          <OtpInput
            onChange={this.inputChange}
            value={this.state.value}
            numInputs={4}
            isInputNum={true}
            shouldAutoFocus={true}
            containerStyle="-container"
            inputStyle="-input"
          />
        </Field>
      </Container>
    )
  }
}

Dob.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Dob
