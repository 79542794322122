import React from "react"
import posed, { PoseGroup } from "react-pose"
import { navigate } from "gatsby"
import styled, { css, keyframes } from "styled-components"
import queryString from "query-string"

import Header from "../components/header"
import StepIndicator from "../components/index/step-indicator"
import Intro from "../components/index/intro"
import Country from "../components/index/country"
import Dob from "../components/index/dob"
import Gender from "../components/index/gender"
import Calculating from "../components/index/calculating"

const animStepIndicatorWrap = keyframes`
  0%,
  50% { opacity: 0; transform: translateY(-5vh); }
  100% { opacity: 1; transform: translateY(0); }
`

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const HeaderWrap = styled.div`
  margin-top: 50vh;
  transform: translateY(-50%);
  transition: 2s ${props => props.theme.easingInOutSine};
  transition-property: margin-top, transform;
  will-change: margin-top, transform;

  ${props =>
    props.placeToTop &&
    css`
      margin-top: 0;
      transform: translateY(0%);
    `}
`

const StepIndicatorWrap = styled.div`
  transform-origin: center center;
  animation: ${animStepIndicatorWrap} 3s ${props => props.theme.easingInOutSine};
`

const Steps = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }
`

const PosedSteps = posed.div({
  enter: {
    transition: { ease: `easeOut`, duration: 500, delay: 500 },
    originX: `50%`,
    originY: `50%`,
    opacity: 1,
    scale: 1,
  },
  exit: {
    transition: { ease: `easeIn`, duration: 500 },
    originX: `50%`,
    originY: `50%`,
    opacity: 0,
    scale: 0.95,
  },
})

class IndexPage extends React.Component {
  tos = []
  dob = null
  gender = null
  country = null

  state = {
    step: `blank`,
    headerAnimate: false,
    country: null,
    dob: null,
    gender: null,
  }

  componentDidMount() {
    document.body.style.overflow = `hidden`

    this.tos.push(
      window.setTimeout(() => {
        this.setState({ headerAnimate: true })

        this.tos.push(
          window.setTimeout(() => {
            this.setState({ step: `intro` })
          }, 5000)
        )

        this.tos.push(
          window.setTimeout(() => {
            document.body.style.overflow = `auto`
          }, 7000)
        )
      }, 100)
    )
  }

  componentWillUnmount() {
    this.tos.forEach(to => window.clearTimeout(to))
  }

  introStart = () => {
    this.setState({ step: `country` })
  }

  countrySelect = countryData => {
    this.country = countryData.country
    this.setState({ step: `dob` })
  }

  dobSelect = dob => {
    this.dob = dob
    this.setState({ step: `gender` })
  }

  genderSelect = gender => {
    this.gender = gender
    this.setState({ step: `calculating` }, () => {
      this.tos.push(
        window.setTimeout(() => {
          // this.setState({ step: "stats" }, () =>
          // this.tos.push(window.setTimeout(() =>
          navigate(
            `/time/?${queryString.stringify({
              country: this.country,
              dob: this.dob,
              gender: this.gender,
            })}`
          )
          // , 1000))
          // )
        }, 2000)
      )
    })
  }

  render() {
    const { step, headerAnimate } = this.state

    return (
      <Container>
        <HeaderWrap placeToTop={step !== `blank`}>
          <Header animate={headerAnimate} />
        </HeaderWrap>

        <Steps>
          <div>
            <PoseGroup>
              <PosedSteps key={step}>
                {step === `intro` && <Intro onSelect={this.introStart} />}

                {step === `country` && (
                  <Country onSelect={this.countrySelect} />
                )}

                {step === `dob` && <Dob onSelect={this.dobSelect} />}

                {step === `gender` && <Gender onSelect={this.genderSelect} />}

                {step === `calculating` && <Calculating />}
              </PosedSteps>
            </PoseGroup>
          </div>
        </Steps>

        {step !== `blank` && step !== `intro` && (
          <StepIndicatorWrap>
            <StepIndicator step={step} />
          </StepIndicatorWrap>
        )}
      </Container>
    )
  }
}

export default IndexPage
