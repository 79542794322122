import React from "react"
import ReactModal from "react-modal"
import FilterResults from "react-filter-search"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"
import rgbHex from "rgb-hex"

import colors from "../../theme/sections/colors"
import zIndexes from "../../theme/sections/z-index"

import ageData from "../../data/age-data.json"

import imgPattern from "../../assets/images/pattern.jpg"

const animContainer = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`

const customStyles = {
  // http://reactcommunity.org/react-modal/styles/
  overlay: {
    position: `fixed`,
    zIndex: zIndexes.ziModal,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `transparent`,
  },
  content: {
    padding: 0,
    position: `static`,
    overflow: `visible`,
    WebkitOverflowScrolling: `auto`,
    outline: `none`,
    borderRadius: 0,
    border: `none`,
    background: `transparent`,
    color: colors.colorWhite,
  },
}

const Container = styled.div`
  height: 100vh;
  padding: ${rem(80)};
  padding-bottom: ${rem(240)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  color: ${props => props.theme.colorWhite};
  background-image: linear-gradient(
      120deg,
      rgba(8, 27, 51, 0.9) 30%,
      rgba(8, 27, 51, 0) 100%
    ),
    url(${imgPattern});
  background-size: auto, ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  animation: ${animContainer} 0.5s linear;
  position: relative;

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(30)};
  }

  input[type="search"] {
    width: 100%;
    font-size: ${rem(36)};
    display: block;
    padding-left: 2em;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.25em 100%;
    background-image: url("data:image/svg+xml,${props =>
      encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.5 38.5">
        <path
          d="M38.2 36.7l-9.4-9.4c2.5-2.9 4.1-6.7 4.1-10.8 0-9.1-7.4-16.5-16.5-16.5-9 0-16.4 7.4-16.4 16.4s7.4 16.4 16.4 16.4c4.1 0 7.9-1.5 10.8-4.1l9.4 9.4c.2.2.5.3.8.3.3 0 .6-.1.8-.3.5-.3.5-1 0-1.4zm-21.8-6c-7.9 0-14.3-6.4-14.3-14.3s6.5-14.2 14.3-14.2 14.3 6.4 14.3 14.3-6.4 14.2-14.3 14.2z"
          fill="#${rgbHex(props.theme.colorWhite)}"
        />
      </svg>
    `)}");

    @media ${props => props.theme.mediumDown} {
      font-size: ${rem(18)};
    }
  }
 `

const List = styled.ul`
  font-size: ${rem(36)};
  margin-top: ${rem(30)};

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(18)};
  }

  button {
    width: 100%;
    display: block;
    position: relative;

    &:hover {
      opacity: 0.8;

      &::before {
        content: "";
        width: 0.4em;
        height: 0.4em;
        margin-right: 0.6em;
        position: absolute;
        top: 0.5em;
        right: 100%;
        border-radius: 50%;
        background-color: currentColor;
      }
    }

    &:active {
      opacity: 0.6;
    }
  }
`

class CountrySelect extends React.Component {
  state = {
    searchValue: ``,
  }

  componentDidMount() {
    ReactModal.setAppElement(`#___gatsby`)
  }

  modalAfterOpen = () => {
    this.inputEl.focus()
  }

  inputChange = () => {
    const { value } = this.inputEl
    this.setState({ searchValue: value })
  }

  countryClick = e => {
    e.preventDefault()
    const { countryName } = e.currentTarget.dataset
    const data = ageData.find(o => o.country === countryName)
    this.props.onSelect(data)
  }

  render() {
    const { searchValue } = this.state

    return (
      <ReactModal
        style={customStyles}
        onAfterOpen={this.modalAfterOpen}
        isOpen={true}
      >
        <Container>
          <input
            type="search"
            placeholder="Type to search&hellip;"
            value={searchValue}
            onChange={this.inputChange}
            ref={el => (this.inputEl = el)}
          />

          <FilterResults
            value={searchValue}
            data={ageData}
            renderResults={results => (
              <List>
                {results.map((item, i) => (
                  <li key={i}>
                    <button
                      type="button"
                      data-country-name={item.country}
                      onClick={this.countryClick}
                    >
                      {item.country}
                    </button>
                  </li>
                ))}
              </List>
            )}
          />
        </Container>
      </ReactModal>
    )
  }
}

CountrySelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default CountrySelect
