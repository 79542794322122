import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import CountrySelect from "./country-select"

import imgPattern from "../../assets/images/pattern.jpg"

const Container = styled.div`
  text-align: center;

  h1 {
    margin-bottom: ${rem(40)};
  }
`

const Button = styled.button`
  ${props => props.theme.shapeNotched()}

  width: 90%;
  max-width: ${rem(440)};
  margin: 0 auto;
  padding: 1.2em;
  display: block;
  font-size: ${rem(18)};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorBlueDark};
  background-image: url(${imgPattern});
  background-size: ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  transition: transform 0.2s ${props => props.theme.easingInOutSine};

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    opacity: 0.8;
  }

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(14)};
  }
`

class Country extends React.Component {
  state = {
    displaySelect: false,
  }

  toggleClick = e => {
    e.preventDefault()
    this.setState({ displaySelect: true })
  }

  countrySelect = countryData => {
    this.setState({ displaySelect: false })
    this.props.onSelect(countryData)
  }

  render() {
    const { onSelect, ...props } = this.props // eslint-disable-line
    const { displaySelect } = this.state

    return (
      <React.Fragment>
        <Container {...props}>
          <h1 className="styled-h1">What’s your place of birth?</h1>

          <Button type="button" onClick={this.toggleClick}>
            Click to choose a country
          </Button>
        </Container>

        {displaySelect && <CountrySelect onSelect={this.countrySelect} />}
      </React.Fragment>
    )
  }
}

Country.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Country
