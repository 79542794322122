import React from "react"
import SplitText from "react-pose-text"
import styled from "styled-components"

const Container = styled.div`
  text-align: center;
`

const charPoses = {
  start: {
    y: 0,
    transition: ({ charInWordIndex }) => ({
      type: `spring`,
      velocity: 100 * Math.sin(1 + charInWordIndex),
      damping: 0,
    }),
  },
  finish: {
    y: 0,
    transition: {
      type: `spring`,
      damping: 10,
      stiffness: 1000,
    },
  },
}

class Calculating extends React.Component {
  tos = []

  state = {
    pose: ``,
  }

  componentDidMount() {
    this.tos.push(
      window.setTimeout(() => {
        this.setState({ pose: `start` })

        this.tos.push(
          window.setTimeout(() => {
            this.setState({ pose: `finish` })
          }, 300)
        )
      }, 1000)
    )
  }

  componentWillUnmount() {
    this.tos.forEach(to => window.clearTimeout(to))
  }

  render() {
    const { ...props } = this.props

    return (
      <Container {...props}>
        <h1 className="styled-h1">
          <SplitText pose={this.state.pose} charPoses={charPoses}>
            Calculating your probable time of death&hellip;
          </SplitText>
        </h1>
      </Container>
    )
  }
}

export default Calculating
