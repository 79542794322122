import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { rem } from "polished"
import classNames from "classnames"
import rgbHex from "rgb-hex"

import SvgCountry from "../../assets/images/steps/country.inline.svg"
import SvgDob from "../../assets/images/steps/dob.inline.svg"
import SvgGender from "../../assets/images/steps/gender.inline.svg"
import SvgCalculating from "../../assets/images/steps/calculating.inline.svg"

const animStepDone = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Container = styled.ul`
  padding: ${rem(50)} 0;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 40%;
    height: 100vh;
    background: white;
    z-index: -1;
  }

  &::before {
    left: 0;
    box-shadow:
      -50px 0 50px rgba(8, 27, 31, .03),
      -100px 0 100px rgba(8, 27, 31, .03);
  }

  &::after {
    right: 0;
    box-shadow:
      50px 0 50px rgba(8, 27, 31, .03),
      100px 0 100px rgba(8, 27, 31, .03);
  }

  li {
    color: gray;
    transition: 0.5s ${props => props.theme.easingInOutSine};
    transition-property: margin-top, color;

    &:not(:last-child) {
      margin-right: 0.5em;
    }

    &.--current {
      margin-top: -1em;
      color: black;

      @media ${props => props.theme.mediumDown} {
        margin-top: -0.5em;
      }
    }

    &.--done {
      color: ${props => props.theme.colorGreen};
      position: relative;

      &::before {
        content: "";
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-bottom: 0.5em;
        transform: translateX(-50%);
        border-radius: 50%;
        border: 2px solid ${props => props.theme.colorGreen};
        transform-origin: center center;
        animation: ${animStepDone} 1s linear;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 60% 60%;
        background-image: url("data:image/svg+xml,${props =>
          encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9">
            <path
              d="M3.5 9c-.3 0-.5-.1-.7-.3l-2.5-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l1.8 1.8 6.8-6.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7.5 7c-.2.2-.5.3-.7.3z"
              fill="#${rgbHex(props.theme.colorGreen)}"
            />
          </svg>
        `)}");
      }
    }
  }

  svg {
    width: 4em;
    height: 4em;
    display: block;

    @media ${props => props.theme.mediumDown} {
      width: 3em;
      height: 3em;
    }
  }
`

class StepIndicator extends React.Component {
  to = null

  state = {
    step: ``,
  }

  componentDidMount() {
    this.to = window.setTimeout(
      () =>
        this.setState({
          step: this.props.step,
        }),
      2000
    )
  }

  componentDidUpdate() {
    if (this.props.step !== this.state.step) {
      this.setState({ step: this.props.step })
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.to)
  }

  render() {
    const { step } = this.state

    const countryClass = classNames({
      "--current": step === `country`,
      "--done": [`dob`, `gender`, `calculating`, `stats`].includes(step),
    })

    const dobClass = classNames({
      "--current": step === `dob`,
      "--done": [`gender`, `calculating`, `stats`].includes(step),
    })

    const genderClass = classNames({
      "--current": step === `gender`,
      "--done": [`calculating`, `stats`].includes(step),
    })

    const calculatingClass = classNames({
      "--current": step === `calculating`,
      "--done": [`stats`].includes(step),
    })

    return (
      <Container>
        <li className={countryClass} title="Country" aria-label="Country">
          <SvgCountry />
        </li>

        <li
          className={dobClass}
          title="Date of birth"
          aria-label="Date of birth"
        >
          <SvgDob />
        </li>

        <li className={genderClass} title="Gender" aria-label="Gender">
          <SvgGender />
        </li>

        <li
          className={calculatingClass}
          title="Calculating&hellip;"
          aria-label="Gender&hellip;"
        >
          <SvgCalculating />
        </li>
      </Container>
    )
  }
}

StepIndicator.propTypes = {
  step: PropTypes.string,
}

export default StepIndicator
