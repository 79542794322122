import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import imgPattern from "../../assets/images/pattern.jpg"

const Container = styled.div`
  text-align: center;
  padding-bottom: ${rem(150)};

  @media ${props => props.theme.largeDown} {
    padding-bottom: ${rem(120)};
  }

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(40)};
  }

  h1 {
    margin-bottom: ${rem(80)};
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: ${rem(1140)};
    line-height: 1.3;
    font-weight: 300;

    @media ${props => props.theme.largeDown} {
      font-size: ${rem(36)};
    }

    @media ${props => props.theme.mediumDown} {
      max-width: ${rem(500)};
      margin-bottom: ${rem(40)};
      font-size: ${rem(24)};
      font-weight: 400;
    }
  }
`

const Button = styled.button`
  ${props => props.theme.shapeNotched()}

  width: 90%;
  max-width: ${rem(440)};
  margin: 0 auto;
  padding: 1.2em;
  display: block;
  font-size: ${rem(18)};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorBlueDark};
  background-image: url(${imgPattern});
  background-size: ${rem(90)};
  background-repeat: repeat;
  background-position: center center;
  transition: transform 0.2s ${props => props.theme.easingInOutSine};

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    opacity: 0.8;
  }

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(14)};
  }
`

class Intro extends React.Component {
  toggleClick = () => {
    this.props.onSelect()
  }

  render() {
    const { onSelect, ...props } = this.props // eslint-disable-line

    return (
      <React.Fragment>
        <Container {...props}>
          <h1 className="styled-h1">
            If you could know the exact time you have left to accomplish
            happiness in your life, would that change how you live it? Would
            knowing your days are numbered help you find meaning?
          </h1>

          <Button type="button" onClick={this.toggleClick}>
            Click to find out!
          </Button>
        </Container>
      </React.Fragment>
    )
  }
}

Intro.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Intro
