import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import SvgMale from "../../assets/images/gender/male.inline.svg"
import SvgFemale from "../../assets/images/gender/female.inline.svg"
import SvgOhter from "../../assets/images/gender/other.inline.svg"

const Container = styled.div`
  text-align: center;

  h1 {
    margin-bottom: ${rem(40)};
  }
`

const List = styled.div`
  display: flex;
  justify-content: center;
`

const Button = styled.button.attrs({ type: `button` })`
  display: block;
  font-size: ${rem(18)};
  text-transform: uppercase;
  text-align: center;
  transition: transform 0.2s ${props => props.theme.easingInOutSine};

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(18)};
  }

  &:hover {
    opacity: 0.8;
    transform: scale(1.15);
  }
  &:active {
    opacity: 0.6;
  }

  &:not(:last-child) {
    margin-right: ${rem(30)};
  }

  svg {
    width: ${rem(90)};
    height: ${rem(90)};
    margin: 0 auto ${rem(10)} auto;
    display: block;

    @media ${props => props.theme.mediumDown} {
      width: ${rem(60)};
      height: ${rem(60)};
    }
  }
`

class Gender extends React.Component {
  buttonClick = e => {
    const { value } = e.currentTarget.dataset
    this.props.onSelect(value)
  }

  render() {
    const { onSelect, ...props } = this.props // eslint-disable-line

    return (
      <Container {...props}>
        <h1 className="styled-h1">What is your gender?</h1>

        <List>
          <Button data-value="male" onClick={this.buttonClick}>
            <SvgMale aria-hidden="true" />
            Male
          </Button>

          <Button data-value="female" onClick={this.buttonClick}>
            <SvgFemale aria-hidden="true" />
            Female
          </Button>

          <Button data-value="other" onClick={this.buttonClick}>
            <SvgOhter aria-hidden="true" />
            Other
          </Button>
        </List>
      </Container>
    )
  }
}

Gender.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Gender
